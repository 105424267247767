'use client';

import { useEffect } from 'react';
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ShowcaseResultRow } from '@/global/pages/Search/_components/ShowcaseResultRow';
import { useRequestPartPriceFromSPW } from '@/hooks/useRequestPartPriceFromSPW';
import { TenantConfig } from '@/shared/types/controllers/sps/SPSAppControllerTypes';
import { SearchPartsResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { PartResultCardMobile } from './PartResultCardMobile';

// hardcoded now for popular parts
const SHIP_PROCESSING_DAYS = 0;
type ShowcaseResultsTableProps = {
  tenantConfig: TenantConfig;
  parts: SearchPartsResponse['parts'];
};
export const ShowcaseResultsTable = ({
  tenantConfig,
  parts
}: ShowcaseResultsTableProps) => {
  const {
    requestMultiplePartsPrices,
    getPartPrice
  } = useRequestPartPriceFromSPW();
  useEffect(() => {
    const partIDs = parts.map(({
      id
    }) => id);

    /**
     * Request the prices for the popular products.
     */
    requestMultiplePartsPrices(partIDs);
  }, [getPartPrice, parts, requestMultiplePartsPrices]);
  const partsWithDiscounts = parts.map(part => ({
    ...part,
    ...getPartPrice(part.id)
  }));
  return <>
      <Stack spacing={2} sx={{
      display: {
        xs: 'block',
        sm: 'none'
      }
    }} data-sentry-element="Stack" data-sentry-source-file="ShowcaseResultsTable.tsx">
        {partsWithDiscounts.map(part => <PartResultCardMobile key={part.id} tenantConfig={tenantConfig} part={{
        ...part,
        price: part.price ?? undefined,
        originalPrice: part.originalPrice ?? undefined,
        shipProcessingDays: SHIP_PROCESSING_DAYS
      }} />)}
      </Stack>
      <TableContainer data-sentry-element="TableContainer" data-sentry-source-file="ShowcaseResultsTable.tsx">
        <Table size="small" sx={{
        'th,td': {
          px: 1
        },
        'th:first-of-type, td:first-of-type': {
          pl: 2
        },
        'th:last-of-type, td:last-of-type': {
          pr: 2
        },
        'th:nth-child(1),td:nth-child(1)': {
          width: `${4 * 100 / 9}%`
        },
        'th:nth-child(2),td:nth-child(2)': {
          width: `${2 * 100 / 9}%`
        },
        'th:nth-child(3),td:nth-child(3)': {
          width: `${3 * 100 / 9}%`
        }
      }} data-sentry-element="Table" data-sentry-source-file="ShowcaseResultsTable.tsx">
          <TableHead sx={{
          bgcolor: 'tertiary.dark',
          th: {
            color: 'common.white',
            fontSize: 14
          }
        }} data-sentry-element="TableHead" data-sentry-source-file="ShowcaseResultsTable.tsx">
            <TableRow data-sentry-element="TableRow" data-sentry-source-file="ShowcaseResultsTable.tsx">
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="ShowcaseResultsTable.tsx">Item</TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="ShowcaseResultsTable.tsx">Delivery</TableCell>
              <TableCell align="right" data-sentry-element="TableCell" data-sentry-source-file="ShowcaseResultsTable.tsx">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-sentry-element="TableBody" data-sentry-source-file="ShowcaseResultsTable.tsx">
            {partsWithDiscounts.map(part => <ShowcaseResultRow key={part.id} tenantConfig={tenantConfig} part={{
            ...part,
            price: part.price ?? undefined,
            originalPrice: part.originalPrice ?? undefined,
            shipProcessingDays: SHIP_PROCESSING_DAYS
          }} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </>;
};