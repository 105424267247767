import InfoIcon from '@mui/icons-material/Info';
import { Box, Link, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents, getDiscountPercentage } from '@qb/frontend/utils/formatCurrency';
import { getPartMarketableStatus } from '@qb/frontend/utils/getPartMarketableStatus';
import { removeWhiteBackgroundSx } from '@qb/frontend/utils/removeWhiteBackgroundSx';
import { TenantConfig } from '@/shared/types/controllers/sps/SPSAppControllerTypes';
import { SearchPartsResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { generatePartURL } from '@/shared/utils/URLs';
import { DeliveryChip } from './DeliveryChip';
import { QuickQuoteChip } from './QuickQuoteChip';
import { StockFoundChip } from './StockFoundChip';
const TRUNCATE_LENGTH = 80;
type SearchResultRowProps = {
  tenantConfig: TenantConfig;
  part: SearchPartsResponse['parts'][0];
};
export const SearchResultRow = ({
  tenantConfig,
  part
}: SearchResultRowProps) => {
  const combinedStock = part.onHandQty + part.manufacturerStock;
  const discountPercentage = getDiscountPercentage(part.originalPrice, part.price);
  const isDiscounted = !!discountPercentage;
  return <TableRow sx={{
    backgroundColor: 'common.backgroundColor',
    '& td': {
      p: 1,
      verticalAlign: 'top'
    }
  }} data-sentry-element="TableRow" data-sentry-component="SearchResultRow" data-sentry-source-file="SearchResultRow.tsx">
      <TableCell data-sentry-element="TableCell" data-sentry-source-file="SearchResultRow.tsx">
        <Link title={`View Part ${part.partNumber} Page`} href={generatePartURL(part.manufacturer.name, part.partNumber, part.id)} data-sentry-element="Link" data-sentry-source-file="SearchResultRow.tsx">
          <ImageWithFallback tenantAlias={tenantConfig.tenantAlias} src={part.images[0]?.target} alt={`${part.partNumber} on ${tenantConfig.companyName}`} width={100} height={100} bgColorFill="common.white" sx={removeWhiteBackgroundSx} data-sentry-element="ImageWithFallback" data-sentry-source-file="SearchResultRow.tsx" />
        </Link>
      </TableCell>
      <TableCell data-sentry-element="TableCell" data-sentry-source-file="SearchResultRow.tsx">
        <Box sx={{
        pt: 2
      }} data-sentry-element="Box" data-sentry-source-file="SearchResultRow.tsx">
          <Link title={`View Part ${part.partNumber} Page`} href={generatePartURL(part.manufacturer.name, part.partNumber, part.id)} data-sentry-element="Link" data-sentry-source-file="SearchResultRow.tsx">
            <Typography variant="bodySmallPrimary" sx={{
            color: 'primaryShared.main'
          }} data-sentry-element="Typography" data-sentry-source-file="SearchResultRow.tsx">
              {part.partNumber}
            </Typography>
          </Link>
          <Typography title={part.description} variant="bodyMedium" sx={[{
          mt: 1
        }, clampLinesSx(4)]} data-sentry-element="Typography" data-sentry-source-file="SearchResultRow.tsx">
            {part.description.length > TRUNCATE_LENGTH ? part.description.substr(0, TRUNCATE_LENGTH) + '...' : part.description}
          </Typography>
        </Box>
      </TableCell>
      <TableCell data-sentry-element="TableCell" data-sentry-source-file="SearchResultRow.tsx">
        <Typography variant="bodySmallPrimary" sx={{
        pt: 2
      }} data-sentry-element="Typography" data-sentry-source-file="SearchResultRow.tsx">
          {part.manufacturer.name}
        </Typography>
      </TableCell>
      <TableCell data-sentry-element="TableCell" data-sentry-source-file="SearchResultRow.tsx">
        {!!part.price && !isDiscounted && <Typography variant="bodySmallPrimary" sx={{
        pt: 2
      }}>
            {formatCents(part.price)}
          </Typography>}
        {!!part.price && isDiscounted && <>
            <Box sx={{
          bgcolor: 'accent.main',
          pl: 0.5,
          pt: 0.5,
          pb: 0.5,
          pr: 2,
          mt: 1,
          mr: 'auto',
          mb: 0.5,
          display: 'inline-block',
          borderRadius: '0 50px 50px 0'
        }}>
              <Typography variant="bodySmallPrimary" sx={{
            fontWeight: 600,
            color: 'text.primary'
          }}>
                {formatCents(part.price)}
                {!!part.originalPrice && <Typography component="span" variant="bodySmall" sx={{
              fontSize: 10,
              fontWeight: 600,
              display: 'inline-block',
              textDecoration: 'line-through',
              ml: 1,
              position: 'relative',
              top: '-1px'
            }}>
                    {formatCents(part.originalPrice)}
                  </Typography>}
              </Typography>
            </Box>
            <Typography variant="bodySmallPrimary" sx={{
          color: 'text.primary',
          fontSize: 10,
          mt: 0,
          whiteSpace: 'nowrap'
        }}>
              Your special price{' '}
              <Tooltip title="This price includes special discounts for your company.">
                <InfoIcon sx={{
              fontSize: 10,
              cursor: 'pointer',
              mb: '-2px'
            }} />
              </Tooltip>
            </Typography>
          </>}
      </TableCell>
      {getPartMarketableStatus(part) === 'marketable' && <>
          <TableCell>
            <Box sx={{
          pt: 1.5
        }}>
              <StockFoundChip stock={combinedStock} />
            </Box>
          </TableCell>
          <TableCell>
            <Box sx={{
          pt: 1.5
        }}>
              <DeliveryChip shipProcessingDays={part.shipProcessingDays} />
            </Box>
          </TableCell>
        </>}
      {getPartMarketableStatus(part) === 'hasStockNoPrice' && <>
          <TableCell>
            <Box sx={{
          pt: 1.5
        }}>
              <StockFoundChip stock={combinedStock} />
            </Box>
          </TableCell>
          <TableCell>
            <Box sx={{
          pt: 1.5
        }}>
              <QuickQuoteChip />
            </Box>
          </TableCell>
        </>}
      {getPartMarketableStatus(part) === 'notMarketable' && <TableCell colSpan={2}>
          <Box sx={{
        pt: 1.5
      }}>
            <QuickQuoteChip />
          </Box>
        </TableCell>}
    </TableRow>;
};