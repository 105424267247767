import { useState, PropsWithChildren } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
type FilterAccordionProps = PropsWithChildren<{
  title: string;
  defaultExpanded?: boolean;
}>;
export const FilterAccordion = ({
  title,
  defaultExpanded = false,
  children
}: FilterAccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  return <Accordion disableGutters sx={{
    p: 0,
    borderWidth: 0,
    borderBottomWidth: 1,
    '&::before': {
      display: 'none'
    },
    '&.MuiAccordion-root:last-of-type': {
      borderBottomWidth: 0
    }
  }} expanded={isExpanded} onChange={() => setIsExpanded(prev => !prev)} slotProps={{
    transition: {
      unmountOnExit: true
    }
  }} data-sentry-element="Accordion" data-sentry-component="FilterAccordion" data-sentry-source-file="FilterAccordion.tsx">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${title.toLowerCase()}-content`} id={`${title.toLowerCase()}-header`} data-sentry-element="AccordionSummary" data-sentry-source-file="FilterAccordion.tsx">
        <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="FilterAccordion.tsx">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{
      p: 0,
      pb: 1,
      pl: 1.5,
      borderWidth: 0
    }} data-sentry-element="AccordionDetails" data-sentry-source-file="FilterAccordion.tsx">
        {children}
      </AccordionDetails>
    </Accordion>;
};