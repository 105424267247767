import { createContext, useContext, useState, ReactNode, SetStateAction, Dispatch } from 'react';
type IFrameContextType = {
  isIframeLoaded: boolean;
  setIsIframeLoaded: Dispatch<SetStateAction<boolean>>;
};
const IFrameContext = createContext<IFrameContextType | undefined>(undefined);
export const useIFrameContext = () => {
  const context = useContext(IFrameContext);
  if (!context) {
    throw new Error('useIFrame must be used within a IFrameContextProvider');
  }
  return context;
};
export const IFrameContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  return <IFrameContext.Provider value={{
    isIframeLoaded,
    setIsIframeLoaded
  }} data-sentry-element="unknown" data-sentry-component="IFrameContextProvider" data-sentry-source-file="IFrameContextProvider.tsx">
      {children}
    </IFrameContext.Provider>;
};