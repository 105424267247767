'use client';

import { Link as MuiLink, Stack, SxProps, Typography } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { removeWhiteBackgroundSx } from '@qb/frontend/utils/removeWhiteBackgroundSx';
import { getSPSCategoryManufacturerURL, getSPSCategoryURL } from '@/global/utils/spsURLs';
import { GetManufacturerSearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
type ChildCategoryProps = {
  category: {
    id: number;
    name: string;
    imageURL: string;
    numParts?: number;
  };
  manufacturer?: GetManufacturerSearchDataResponse['manufacturer'];
  sx?: SxProps;
  onClick?: () => void;
  tenantAlias: string;
};
export const ChildCategory = ({
  category,
  manufacturer,
  onClick,
  sx,
  tenantAlias
}: ChildCategoryProps) => {
  return <Stack spacing={1} component={MuiLink} onClick={onClick} href={manufacturer ? getSPSCategoryManufacturerURL({
    category,
    manufacturer
  }) : getSPSCategoryURL(category)} sx={{
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    },
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    mt: 1,
    mr: 2,
    ...sx
  }} data-sentry-element="Stack" data-sentry-component="ChildCategory" data-sentry-source-file="ChildCategory.tsx">
      <ImageWithFallback tenantAlias={tenantAlias} src={category.imageURL} alt={category.name} width={60} height={60} sx={removeWhiteBackgroundSx} data-sentry-element="ImageWithFallback" data-sentry-source-file="ChildCategory.tsx" />
      <Typography variant="bodySmall" align="center" component="h3" sx={{
      color: 'text.primary'
    }} data-sentry-element="Typography" data-sentry-source-file="ChildCategory.tsx">
        {category.name} {'numParts' in category && `(${category.numParts})`}
      </Typography>
    </Stack>;
};