import { useEffect, useState } from 'react';
import * as React from 'react';
import { Checkbox, FormControlLabel, List, ListItem, Stack, Typography } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { FilterAccordion } from '@/global/pages/Search/_components/FilterAccordion';
import { ManufacturerFacetChipFilter } from '@/global/pages/Search/_components/ManufacturerFacetChipMobileFilter';
import { useNavigationTransitionContext } from '@/global/pages/Search/_contexts/NavigationTransitionContext';
import { useManufacturerParam } from '@/global/pages/Search/_hooks/useManufacturerParam';
import { SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { isNullableOrEmptyArray } from '@/shared/types/util/TypeGuards';
type ManufacturerFilterProps = {
  manufacturerFacets: SearchDataResponse['manufacturerFacets'];
};
type ManufacturerCheckboxProps = {
  facet: SearchDataResponse['manufacturerFacets'][number];
};
const ManufacturerCheckbox = ({
  facet
}: ManufacturerCheckboxProps) => {
  const {
    updateManufacturerParam,
    isChecked: getIsChecked
  } = useManufacturerParam();
  const isCheckedInitial = getIsChecked(facet);
  const [isChecked, setIsChecked] = useState(isCheckedInitial);
  const {
    isPending
  } = useNavigationTransitionContext();

  // Sync the local state with the URL
  useEffect(() => setIsChecked(isCheckedInitial), [isCheckedInitial]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIsChecked = event.target.checked;
    setIsChecked(newIsChecked);
    updateManufacturerParam(facet, newIsChecked);
  };
  return <FormControlLabel control={<Checkbox size="small" checked={isChecked} onChange={handleChange} disabled={isPending} />} label={<Typography variant="bodySmall" sx={{
    color: 'text.primary'
  }}>
          {facet.name} ({facet.numParts})
        </Typography>} data-sentry-element="FormControlLabel" data-sentry-component="ManufacturerCheckbox" data-sentry-source-file="ManufacturerFilter.tsx" />;
};
export const ManufacturerFilter = ({
  manufacturerFacets
}: ManufacturerFilterProps) => {
  const isMobile = useIsMobile();
  if (isNullableOrEmptyArray(manufacturerFacets)) {
    return null;
  }
  if (isMobile) {
    return <FilterAccordion title="Manufacturer" defaultExpanded>
        <Stack direction="row" sx={{
        flexWrap: 'wrap'
      }} spacing={1}>
          {manufacturerFacets.map(manufacturerFacet => <ManufacturerFacetChipFilter manufacturerFacet={manufacturerFacet} key={manufacturerFacet.id} />)}
        </Stack>
      </FilterAccordion>;
  }
  return <FilterAccordion title="Manufacturer" defaultExpanded data-sentry-element="FilterAccordion" data-sentry-component="ManufacturerFilter" data-sentry-source-file="ManufacturerFilter.tsx">
      <List sx={{
      py: 0
    }} data-sentry-element="List" data-sentry-source-file="ManufacturerFilter.tsx">
        {manufacturerFacets.map(facet => <ListItem key={facet.id} sx={{
        p: 0
      }}>
            <ManufacturerCheckbox facet={facet} />
          </ListItem>)}
      </List>
    </FilterAccordion>;
};