import { createContext, useContext, useState, ReactNode, SetStateAction, Dispatch } from 'react';
import { UserInfo } from '@/shared/types/controllers/AuthControllerTypes';

/**
 * `UserInfo` when user is authenticated. `null` when user is not authenticated. `undefined` when user is loading.
 */
type AuthUserType = UserInfo | null | undefined;
type AuthUserContextType = {
  authUser: AuthUserType;
  setAuthUser: Dispatch<SetStateAction<AuthUserType>>;
};
const AuthUserContext = createContext<AuthUserContextType | undefined>(undefined);
export const useAuthUserContext = () => {
  const context = useContext(AuthUserContext);
  if (!context) {
    throw new Error('useAuthUser must be used within a AuthUserContextProvider');
  }
  return context;
};
export const AuthUserContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [authUser, setAuthUser] = useState<AuthUserType>();
  return <AuthUserContext.Provider value={{
    authUser,
    setAuthUser
  }} data-sentry-element="unknown" data-sentry-component="AuthUserContextProvider" data-sentry-source-file="AuthUserContextProvider.tsx">
      {children}
    </AuthUserContext.Provider>;
};