import { PartPublicQtyAndPriceResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';

type Props = {
  publicQtyAndPrice: Pick<
    PartPublicQtyAndPriceResponse,
    'onHandQty' | 'minimumOrderQty'
  >;
  purchaseQty: number;
  hasPrice: boolean;
};

export const calculateNumberInputIncrement = ({
  publicQtyAndPrice: { onHandQty: supplierStock, minimumOrderQty = 1 },
  purchaseQty,
  hasPrice,
}: Props) => {
  // Only use the custom increment and minimumOrderQty for marketable parts.
  if (!hasPrice) {
    return 1;
  }
  // As long as we are buying from the supplierStock, we can increment by 1 item at a time.
  if (purchaseQty < supplierStock) {
    return 1;
  }
  // once we reach the supplierStock, we can only increment by the minimumOrderQty as these values we will ordered from the manufacturer stocks.
  return minimumOrderQty;
};
