import { createContext, useContext, useState, ReactNode, SetStateAction, Dispatch } from 'react';
import { MultiplePartsPriceResponseEvent } from '@/shared/SalesPilotConstants';
type PriceAndOriginalPriceRequest = {
  status: 'success' | 'queued' | 'pending';
  data?: Pick<MultiplePartsPriceResponseEvent['payload']['pricesAndOriginalPrices'][0], 'originalPrice' | 'price'>;
};
export type PartsMap = Record<number, PriceAndOriginalPriceRequest>;
type WidgetPartsPricesContextType = {
  parts: PartsMap;
  setParts: Dispatch<SetStateAction<PartsMap>>;
};
const WidgetPartsPricesContext = createContext<WidgetPartsPricesContextType | undefined>(undefined);
export const useWidgetPartsPrices = () => {
  const context = useContext(WidgetPartsPricesContext);
  if (!context) {
    throw new Error('useWidgetPartsPrices must be used within a WidgetPartsPricesContextProvider');
  }
  return context;
};
export const WidgetPartsPricesContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [parts, setParts] = useState<PartsMap>({});
  return <WidgetPartsPricesContext.Provider value={{
    parts,
    setParts
  }} data-sentry-element="unknown" data-sentry-component="WidgetPartsPricesContextProvider" data-sentry-source-file="WidgetPartsPricesContextProvider.tsx">
      {children}
    </WidgetPartsPricesContext.Provider>;
};