import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link, TableCell, TableRow, Typography, Stack, Chip } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { getPartMarketableStatus } from '@qb/frontend/utils/getPartMarketableStatus';
import { truncateWithEllipsis } from '@qb/frontend/utils/truncateWithEllipsis';
import { ShowcaseResultRowForm } from '@/global/pages/Search/_components/ShowcaseResultRowForm';
import { TenantConfig } from '@/shared/types/controllers/sps/SPSAppControllerTypes';
import { SearchPartsResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { generatePartURL } from '@/shared/utils/URLs';
const TRUNCATE_LENGTH = 150;
type DeliveryColumnProps = {
  combinedStock: number;
  partMarketableStatus: ReturnType<typeof getPartMarketableStatus>;
};
const DeliveryColumn = ({
  combinedStock,
  partMarketableStatus
}: DeliveryColumnProps) => {
  if (!!combinedStock && (partMarketableStatus === 'marketable' || partMarketableStatus === 'hasStockNoPrice')) {
    return <Chip size="small" icon={<CheckCircleIcon />} color="success" sx={{
      bgcolor: 'success.12p',
      color: 'success.dark',
      '& .MuiChip-iconColorSuccess': {
        color: 'success.dark'
      }
    }} label={`${combinedStock} in stock`} />;
  }

  // 'notMarketable'
  return <Chip size="small" icon={<CheckCircleIcon />} color="info" sx={{
    bgcolor: 'info.12p',
    '& .MuiChip-iconColorInfo': {
      color: 'info.dark'
    },
    '& .MuiChip-label': {
      color: 'info.dark'
    }
  }} label="This item can be backordered" data-sentry-element="Chip" data-sentry-component="DeliveryColumn" data-sentry-source-file="ShowcaseResultRow.tsx" />;
};
type ShowcaseResultRowProps = {
  tenantConfig: TenantConfig;
  part: SearchPartsResponse['parts'][0];
};
export const ShowcaseResultRow = ({
  tenantConfig,
  part
}: ShowcaseResultRowProps) => {
  const combinedStock = part.onHandQty + part.manufacturerStock;
  return <TableRow sx={{
    td: {
      bgcolor: 'common.backgroundColor',
      mb: 1,
      borderBottom: '6px solid',
      borderBottomColor: 'common.white',
      py: 2
    }
  }} data-sentry-element="TableRow" data-sentry-component="ShowcaseResultRow" data-sentry-source-file="ShowcaseResultRow.tsx">
      <TableCell data-sentry-element="TableCell" data-sentry-source-file="ShowcaseResultRow.tsx">
        <Stack direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="ShowcaseResultRow.tsx">
          <div>
            <ImageWithFallback tenantAlias={tenantConfig.tenantAlias} src={part.images[0]?.target} alt={`${part.partNumber} on ${tenantConfig.companyName}`} width={73} height={73} data-sentry-element="ImageWithFallback" data-sentry-source-file="ShowcaseResultRow.tsx" />
          </div>
          <div>
            <Link title={`View Part ${part.partNumber} Page`} href={generatePartURL(part.manufacturer.name, part.partNumber, part.id)} data-sentry-element="Link" data-sentry-source-file="ShowcaseResultRow.tsx">
              <Typography variant="bodySmallPrimary" sx={{
              mt: 0.5,
              color: 'primaryShared.main'
            }} data-sentry-element="Typography" data-sentry-source-file="ShowcaseResultRow.tsx">
                {part.partNumber}
              </Typography>
            </Link>
            <Typography title={part.description} variant="bodySmall" sx={[{
            mt: 1
          }, clampLinesSx(3)]} data-sentry-element="Typography" data-sentry-source-file="ShowcaseResultRow.tsx">
              {truncateWithEllipsis(part.description, TRUNCATE_LENGTH)}
            </Typography>
          </div>
        </Stack>
      </TableCell>
      <TableCell data-sentry-element="TableCell" data-sentry-source-file="ShowcaseResultRow.tsx">
        <DeliveryColumn partMarketableStatus={getPartMarketableStatus(part)} combinedStock={combinedStock} data-sentry-element="DeliveryColumn" data-sentry-source-file="ShowcaseResultRow.tsx" />
      </TableCell>
      <TableCell data-sentry-element="TableCell" data-sentry-source-file="ShowcaseResultRow.tsx">
        <ShowcaseResultRowForm part={part} data-sentry-element="ShowcaseResultRowForm" data-sentry-source-file="ShowcaseResultRow.tsx" />
      </TableCell>
    </TableRow>;
};