import { PartPublicQtyAndPriceResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';

type Props = Pick<
  PartPublicQtyAndPriceResponse,
  'onHandQty' | 'minimumOrderQty'
>;

export const calculateDefaultPurchaseQuantityValue = ({
  onHandQty,
  minimumOrderQty,
}: Props) => {
  return onHandQty > 0 ? 1 : minimumOrderQty;
};
